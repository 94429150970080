define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en", {
    "AddtweaksHelp": "Tweaks (ex: ml10 for margin-left:10%, pre-line for text end line or upright for vertical text)",
    "Cannot delete group with users": "Cannot delete group with users",
    "Cannot process password": "Cannot process password",
    "Cannot send email": "Cannot send email",
    "DownloadDocument": "Download you document",
    "Duplicate group": "This group already exists",
    "Email already taken": "Email already taken",
    "Email is invalid": "Email is invalid",
    "Email not yet verified": "Email not yet verified.\nPlease check your junk mail for the activation link.",
    "Invalid user or password": "Invalid user or password",
    "Invalid user or password. Your account is now locked for": "Invalid user or password. Your account is now locked for",
    "Invalid user or password. Your account will be locked soon": "Invalid user or password. Your account will be locked soon.",
    "NoDocument": "No Document",
    "PageNotFound": "Oops, the page you're looking for wasn't found!",
    "Password do not match": "Password do not match",
    "Please enter your email/username and password": "Please enter your email/username and password",
    "Please enter your new password": "Please enter your new password",
    "User not authenticated": "The session has expired",
    "Username already taken": "User name already taken",
    "Username has to start with a lowercase letter (a-z)": "Username has to start with a lowercase letter (a-z)",
    "Username may not contain any non-url-safe characters": "Username may not contain any non-url-safe characters",
    "Username must be lowercase": "Username must be lowercase",
    "acceptPermit": "Response obtained",
    "accountNowActive": "Your account is now active.",
    "addPermit": "Add permit",
    "additionalInformation": "Additional information",
    "administration": "Administration",
    "all": "All",
    "allOrg": "All orginizations",
    "app": "App",
    "appName": "Network Viewer",
    "application": "Application",
    "attribute": "Attribute",
    "availabilityOfServices": "Availability of services",
    "backToHome": "Back to home",
    "backToList": "Back to list",
    "backToLoginPage": "Back to login page",
    "backToResults": "Back to results",
    "backToSearch": "Back to search",
    "basemap": "basemap",
    "basicInformation": "Basic information",
    "cancel": "Cancel",
    "cannot_remove_owner": "Cannot remove project owner",
    "carto": "carto",
    "cartographyModel": "Cartography model",
    "catalog": "Catalog",
    "catalogShort": "📕",
    "changePassword": "Change password",
    "checkEmailInfo": "We have just sent you a link to reset your password. If you can't find it in your inbox, be sure to check your spam folder.",
    "checkEmailMessage": "Check if an email from Zonedge is in your inbox.",
    "checkYourEmailInbox": "Check if an email from Zonedge is in your inbox.",
    "chooseAModel": "Choose a model",
    "chooseAMunicipality": "Choose a municipality",
    "chooseAStreet": "Choose a street",
    "circuit": "Circuit",
    "civicNumber": "Choose a street",
    "clearTrace": "Clear tracing",
    "close": "Close",
    "color": "Color",
    "comments": "Comments",
    "commentsConstruction": "Comments construction",
    "commentsTerrain": "Comments terrain",
    "confirm": "Confirm",
    "confirmBtn": "Confirm",
    "confirmDelete": "Confirm deletion",
    "confirmDeleteAccount": "Do you want to delete your account ?",
    "confirmDeleteProject": "Do you confirm to delete this project ?",
    "confirmLeaveProject": "Do you confirm to quit this project ?",
    "confirmPassword": "Confirm password",
    "confirmReplaceEdition": "Do you want to cancel changes and begin a new edition ?",
    "confirmYourProjectAdhesion": "Confirm your project adhesion",
    "confirmYourZonedgeAccount": "Confirm your Zonedge account",
    "connectedAs": "You are",
    "connectedAsEmail": "You are currently connected using {email}",
    "connectionToZonedge": "Connection to Zonedge",
    "construction": "Construction",
    "constructionAttrHelp": "Attributes to expose for construction edition (limited)",
    "constructionShort": "🚧",
    "contributors": "Contributors",
    "controlAccessContributorsInformation": "Control the access of your contributors to the project. The added contributors will have access to shared resources in the project.",
    "copyMapView": "Edit in a copy of the model",
    "createANewProject": "Create a new project",
    "createAZonedgeAccount": "Create a Zonedge account",
    "createAZonedgeAccountSendConfirmEmail": "We have just sent you a link to confirm the creation of your Zonedge account. If you can't find it in your inbox, be sure to check your spam folder.",
    "createAnAccount": "Create an account",
    "createProject": "Create project",
    "createRunSelectSQL": "Run Select SQL",
    "createUpdateSQL": "Create UPDATE SQL",
    "csv": "CSV",
    "current password is incorrect": "Current password is incorrect",
    "currentPassword": "Current password",
    "dark": "Dark",
    "dashboard": "Interface",
    "day": "{count, plural, =0 {day} one {day} other {days}}",
    "delete": "Delete",
    "deleteAccount": "Delete account",
    "deleteAccountInformation": "If you delete your Zonedge account, you will no longer be able to access to your data. Once your account is deleted, all information will no longer be recoverable.",
    "deleteTheProject": "Delete the project",
    "deleteTheProjectInformation": "If you delete this project, you will no longer be able to access it and your configurations will no longer be recoverable.",
    "demandPermit": "Demand",
    "demanderPermit": "Demander",
    "description": "Description",
    "details": "Details",
    "directWithoutEmail": "Without notification",
    "displayAll": "Display all",
    "distance": "Distance",
    "documentCount": "{count, plural, =0 {No document} =1 {Document} other {Documents}}",
    "done": "Done.",
    "download": "Download",
    "downloadstarted": "Your export request is processing... it may take several minutes.",
    "downstream": "Downstream",
    "duplicate": "Duplicate",
    "dynamicPositionMsg": "Leave empty for dynamic position. Default zoom is 5.",
    "edit": "Edit",
    "editAProject": "Project edit",
    "editDataLoader": "Edit dataloader",
    "editFusion": "Edit fusion",
    "editPermit": "Edit permit",
    "editProject": "Edit project",
    "editors": "Editors",
    "editorsAccessInformation": "Control the access of note editors to the project. The added editors will be able to: add, modify and delete notes in Zonedge.",
    "email": "Email",
    "enterCurrentPassword": "You must enter the your current password",
    "enterNewPassword": "You must enter the your new password",
    "expired": "expired",
    "export": "Export",
    "export in progress": "There is already an export in progress",
    "exportData": "Export data",
    "exportPhoto": "Export photos",
    "feet": "feet",
    "fieldObservation": "Field Observation",
    "file": "File",
    "forgotPwd": "Forgot",
    "forgotYourPassword": "Forgot your password?",
    "fullName": "Full name",
    "fusion": "Fusion",
    "fusionAttrHelp": "Attributes to expose for fusion edition (limited)",
    "fusionShort": "Ⓕ",
    "ground": "Ground",
    "group": "Group",
    "groupManager": "Groups Manager",
    "groups": "Groups",
    "home": "Home",
    "imageCount": "{count, plural, =0 {No photo} =1 {One photo found} other {# photos found}}",
    "infoAProject": "Project info",
    "invalid token": "Invalid token",
    "invitationIsNoLongerValid": "This invitation is no longer valid.\nContact the system administrator to obtain a valid invitation.",
    "invitationNotForYou": "No invitation for {email}, please make sure you are connected with the proper user.\nOr contact the system administrator to obtain a valid invitation.",
    "invite": "Invite",
    "inviteContributorsToJoinTheProject": "Invite contributors to join the project.",
    "kml": "KML",
    "label": "Label",
    "label_anchorAttrHelp": "Align the text at this location in the text box",
    "label_angleAttrHelp": "Attribute or number",
    "label_box_heightAttrHelp": "Height of the text box Default to 60",
    "label_box_widthAttrHelp": "Width of the text box Default to 60",
    "label_correctionAttrHelp": "Correction class CSS",
    "label_heightAttrHelp": "Font size",
    "label_offsetAttrHelp": "Offset direction from insert point",
    "label_outline_colorAttrHelp": "Text outline",
    "label_position_strictAttrHelp": "Show event if segment do not fit box",
    "label_raw_angleAttrHelp": "Don't flip value for reading",
    "label_theme_boundAttrHelp": "Show only if theme with name show",
    "label_weightAttrHelp": "Bold (0-1000)",
    "layer": "Layer",
    "layers": "Layers",
    "leaveTheProject": "Leave the project",
    "leaveTheProjectInformation": "If you leave this project, you will no longer be able to access it. You will need to receive an invitation to join the project again.",
    "light": "Light",
    "linestringSectionMsg": "Section linestring geometry",
    "locale_en": "English",
    "locale_fr": "Français",
    "locateMe": "Show my location",
    "locatePopup": "<br>You are within {distance} {unit} from this point<br><br>",
    "logIn": "Login",
    "logInToZonedge": "Login to Zonedge",
    "login": "Login",
    "mainStyle": "Main Style",
    "map_editor": "Map Editor",
    "map_zonedge": "Map Zonedge",
    "marker_angleAttrHelp": "Attribute or number",
    "marker_heightAttrHelp": "Default to 30",
    "marker_horizontal_alignAttrHelp": "HOffset direction from insert point",
    "marker_opacityAttrHelp": "(0.0-1.0)",
    "marker_vertical_alignAttrHelp": "VOffset direction from insert point",
    "marker_widthAttrHelp": "Default to 30",
    "maxResultReach": "Too much results",
    "max_zoomAttrHelp": "Show if map zoom GREATER or EQUAL (0 is unlimited)",
    "measure_end": "Measure End",
    "measure_length": "Measure Length",
    "measure_start": "Measure Start",
    "members": "Members",
    "menu": "Menu",
    "meters": "meters",
    "min_zoomAttrHelp": "Show if map zoom SMALLER or EQUAL (0 is unlimited)",
    "missing currentPassword": "Missing current password",
    "missing name": "Missing Name",
    "missing newPassword": "Missing new password",
    "missingEmail": "Missing Email",
    "missingPassword": "Missing Password",
    "missingUserName": "Missing User Name",
    "modelNameAlreadyUsed": "This model already exists",
    "municipality": "Municipality",
    "myProjects": "My projects",
    "name": "Name",
    "newLayerName": "New layer",
    "newModelName": "New Model Name",
    "newPassword": "New password",
    "newSearch": "New search",
    "newSymbol": "New Symbol",
    "newUser": "New user",
    "noFibreToTrace": "No fibre found",
    "noInformation": "No information",
    "noPermit": "No",
    "noProject": "No. Project",
    "noResultsForTheseSearchCriteria": "No results for these search criteria.",
    "not_found": "not found",
    "note": "Note",
    "noteRole": "Note",
    "noteRoleShort": "📋",
    "notes": "Notes",
    "ok": "OK",
    "onResult": "{searchOffset} on {searchCount, plural, =1 {1 result} other {# results}}",
    "opacityAttrHelp": "(0.0-1.0)",
    "openPermit": "Expected response",
    "options": "Options",
    "organization": "Organization",
    "outlineStyle": "Outline Style",
    "owner": "Owner",
    "password": "Password",
    "passwordChangedSuccessfully": "Password changed successfully.",
    "passwordDoNotMatch": "Password do not match",
    "passwordMismatch": "The new password does'nt match the confirm field",
    "pendingInvitations": "Pending invitations",
    "permissions": "Permissions",
    "permit": "Permit",
    "permitShort": "🫱",
    "plan": "Plan",
    "pleaseCreateANewPassword": "Please create a new password containing at least 8 characters.",
    "profile": "Profile",
    "project": "Project",
    "projectID": "Project ID",
    "projectName": "Project name",
    "projects": "Projects",
    "read": "Read",
    "readOnly": "Read only",
    "readShort": "📖",
    "receiverPermit": "Receiver",
    "removeCircuitLayerConfirm": "Do you want to remove this circuit layer?",
    "resetPassword": "Reset password",
    "resetPwd": "Reset",
    "resettingPasswordInstruction": "Enter your email and we'll send you instructions to reset your password.",
    "resettingYourPassword": "Resetting your password",
    "restrictions": "Restrictions:",
    "rights": "Rights",
    "runUpdateSQL": "Run UPDATE SQL",
    "satellite": "Satellite",
    "satellitePlan": "Satellite-Plan",
    "save": "Save",
    "savedSuccessfully": "Saved successfully.",
    "saving": "Saving...",
    "search": "Search",
    "searchAddressKnowAvailableServices": "Search for an address to know the available services.",
    "searchCircuit": "Find a circuit",
    "searchResults": "Search results",
    "searchVerb": "Search",
    "selectAFibreTracing": "Select a fibre to trace",
    "selectToEditFusion": "Select to edit fusion",
    "selectToEditPermit": "Select to edit permit",
    "sendEmail": "Send Email",
    "sendEmailExplain": "You will receive an email with a download link once the images are ready",
    "sendInstructions": "Send instructions",
    "services": "Services",
    "servicesAvailableAtTheAddress": "Services available at the address",
    "showMoreResults": "Show more results",
    "shp": "ShapeFile",
    "signOut": "Sign out",
    "signup": "Signup",
    "sorryAnErrorOccurred": "Sorry, an error occurred.",
    "status": "Status",
    "statusTerrain": "Status terrain",
    "street": "Street",
    "super": "Super",
    "superShort": "ⓢ",
    "theChangesHaveBeenSavedSuccessfully": "The changes have been saved successfully.",
    "theme": "Theme",
    "title": "Title",
    "token expired": "Token expired",
    "token not found": "Token not found",
    "trace": "Trace",
    "traceVerb": "Trace",
    "tracingAttrHelp": "What detail element and attribute (fibre id) to use for tracing",
    "twoFactorRequired": "Require 2FA",
    "type": "Type",
    "typeNote": "Note type",
    "uniqueProjectIdentifier": "Unique project identifier",
    "update": "Update",
    "upstream": "Upstream",
    "useDataLoader": "Use dataloader",
    "user": "User",
    "user not_found": "User not found",
    "userManager": "Users Manager",
    "username": "Username",
    "users": "Users",
    "version": "Version {value}",
    "viewMyProfile": "View my profile",
    "visibleZone": "Visible area only",
    "weightAttrHelp": "Stroke thickness",
    "welcomeToZonedge": "Welcome to Zonedge",
    "write": "Write️",
    "writeShort": "✏️",
    "youHaveSuccessfullyJoinedTheProject": "You have successfully joined the project.\nYou can now continue.",
    "yourPasswordHasBeenSuccessfullyChanged": "Your password has been successfully changed."
  }], ["fr", {
    "AddtweaksHelp": "Ajustements (ex: ml10 pour margin-left:10%, pre-line pour respecter les sauts de ligne ou upright pour text vertical)",
    "Cannot delete group with users": "Impossible de supprimer un group avec des utilisateurs",
    "Cannot process password": "Impossible de traiter le mot de passe",
    "Cannot send email": "Impossible d'envoyer un e-mail",
    "DownloadDocument": "Télécharger votre document",
    "Duplicate group": "Ce groupe existe déjà",
    "Email already taken": "Courriel déjà utilisé",
    "Email is invalid": "Courriel est invalide",
    "Email not yet verified": "Le courriel n'est pas encore activé.\nVérifiez dans votre boîte de pourriels pour le lien d'activation.",
    "Invalid user or password": "Utilisateur ou mot de passe invalide",
    "Invalid user or password. Your account is now locked for": "Utilisateur ou mot de passe non valide. Votre compte est maintenant verrouillé pour",
    "Invalid user or password. Your account will be locked soon": "Utilisateur ou mot de passe non valide. Votre compte sera bientôt verrouillé.",
    "NoDocument": "Pas de document",
    "PageNotFound": "Oups, la page n'est pas trouvée!",
    "Password do not match": "Le mot de passe ne correspond pas",
    "Please enter your email/username and password": "Veuillez entrer votre email/nom d'utilisateur et mot de passe",
    "Please enter your new password": "Veuillez saisir votre nouveau mot de passe",
    "User not authenticated": "La session à expirée",
    "Username already taken": "Nom d'utilisateur déjà utilisé",
    "Username has to start with a lowercase letter (a-z)": "Le nom d'utilisateur doit commencer par une lettre minuscule (a-z)",
    "Username may not contain any non-url-safe characters": "Le nom d'utilisateur ne peut contenir aucun caractère non conforme pour les URL",
    "Username must be lowercase": "Le nom d'utilisateur doit être en minuscules",
    "acceptPermit": "Réponse obtenue",
    "accountNowActive": "Votre compte est maintenant actif.",
    "addPermit": "Ajouter un permis",
    "additionalInformation": "Information additionnelle",
    "administration": "Administration",
    "all": "Tout",
    "allOrg": "Toutes les orginisations",
    "app": "App",
    "appName": "Visionneuse Réseau",
    "application": "Application",
    "attribute": "Attribut",
    "availabilityOfServices": "Disponibilité des services",
    "backToHome": "Retour à l'accueil",
    "backToList": "Retour à la liste",
    "backToLoginPage": "Retour à la page de connexion",
    "backToResults": "Retour aux résultats",
    "backToSearch": "Retour à la recherche",
    "basemap": "Plan de base",
    "basicInformation": "Information de base",
    "cancel": "Annuler",
    "cannot_remove_owner": "Le propriétaire du projet ne peut pas étre retiré.",
    "carto": "carto",
    "cartographyModel": "Modèle de la cartographie",
    "catalog": "Catalogue",
    "catalogShort": "📕",
    "changePassword": "Changer le mot de passe",
    "checkEmailInfo": "Nous venons de vous envoyer un lien pour la réinitialisation de votre mot de passe. Si vous ne le trouvez pas dans votre boite de réception, pensez à vérifier le dossier des pourriels.",
    "checkEmailMessage": "Vérifier si un courriel de Zonedge se trouve dans votre boite de réception.",
    "checkYourEmailInbox": "Vérifier si un courriel de Zonedge se trouve dans votre boite de réception.",
    "chooseAModel": "Choisir un modèle",
    "chooseAMunicipality": "Choisir une municipalité",
    "chooseAStreet": "Choisir une rue",
    "circuit": "Circuit",
    "civicNumber": "No. civique",
    "clearTrace": "Effacer le traçage",
    "close": "Fermer",
    "color": "Couleur",
    "comments": "Commentaires",
    "commentsConstruction": "Commentaires construction",
    "commentsTerrain": "Commentaires terrain",
    "confirm": "Confirmation",
    "confirmBtn": "Confirmer",
    "confirmDelete": "Confirmer la suppression",
    "confirmDeleteAccount": "Voulez-vous vraiment supprimer votre compte ?",
    "confirmDeleteProject": "Vouslez-vous vraiment supprimer ce projet ?",
    "confirmLeaveProject": "Voulez-vous vraiment quitter ce projet ?",
    "confirmPassword": "Confirmer le mot de passe",
    "confirmReplaceEdition": "Voulez-vous annuler les changements et procéder ?",
    "confirmYourProjectAdhesion": "Confirmer votre adhésion au projet",
    "confirmYourZonedgeAccount": "Confirmer votre compte Zonedge",
    "connectedAs": "Vous êtes",
    "connectedAsEmail": "Vous êtes présentement connecté avec {email}",
    "connectionToZonedge": "Connexion à Zonedge",
    "construction": "Construction",
    "constructionAttrHelp": "Attributs en edition (limité)",
    "constructionShort": "🚧",
    "contributors": "Contributeurs",
    "controlAccessContributorsInformation": "Contrôlez l'accès de vos contributeurs au projet. Les contributeurs ajoutés auront accès aux ressources partagés dans ce projet.",
    "copyMapView": "Édition dans une copie du modèle carto.",
    "createANewProject": "Créer un nouveau projet",
    "createAZonedgeAccount": "Créer un compte Zonedge",
    "createAZonedgeAccountSendConfirmEmail": "Nous venons de vous envoyer un lien pour confirmer la création de votre compte Zonedge. Si vous ne le trouvez pas dans votre boite de réception, pensez à vérifier le dossier des pourriels.",
    "createAnAccount": "Créer un Compte",
    "createProject": "Créer un projet",
    "createRunSelectSQL": "Exécuter SQL SELECT",
    "createUpdateSQL": "Créer SQL UPDATE",
    "csv": "CSV",
    "current password is incorrect": "Mot de passe est incorrect",
    "currentPassword": "Mot de passe actuel",
    "dark": "Sombre",
    "dashboard": "Interface",
    "day": "{count, plural, =0 {jour} one {jour} other {jours}}",
    "delete": "Supprimer",
    "deleteAccount": "Supprimer le compte",
    "deleteAccountInformation": "Si vous supprimer votre compte Zonedge, vous ne pourrez plus accéder à vos données. Une fois votre compte supprimé, toutes les informations ne seront plus récupérable.",
    "deleteTheProject": "Supprimer le projet",
    "deleteTheProjectInformation": "Si vous supprimer ce projet, vous ne pourrez plus y accéder et vos configurations ne seront plus récupérables.",
    "demandPermit": "Demande",
    "demanderPermit": "Demandeur",
    "description": "Description",
    "details": "Détails",
    "directWithoutEmail": "Sans notification",
    "displayAll": "Afficher tout",
    "distance": "Distance",
    "documentCount": "{count, plural, =0 {Pas de document} =1 {Document} other {Documents}}",
    "done": "Terminé.",
    "download": "Télécharger",
    "downloadstarted": "Votre de demande est en traitement... Cela peut prendre plusieurs minutes.",
    "downstream": "Aval",
    "duplicate": "Dupliquer",
    "dynamicPositionMsg": "Laisser vide pour position dynamique. Zoom par défault est 5.",
    "edit": "Modifier",
    "editAProject": "Modifier le projet",
    "editDataLoader": "Modifier un plan de chargement",
    "editFusion": "Modifier la fusion",
    "editPermit": "Modifier le permis",
    "editProject": "Modifier un projet",
    "editors": "Éditeurs",
    "editorsAccessInformation": "Contrôlez l'accès des éditeurs de notes au projet. Les éditeurs ajoutés pourront: ajouter, modifier et supprimer des notes dans Zonedge.",
    "email": "Adresse courriel",
    "enterCurrentPassword": "Vous devez entrer votre mot de passe actuel",
    "enterNewPassword": "Vous devez entrer votre nouveau mot de passe",
    "expired": "expirée",
    "export": "Exporter",
    "export in progress": "Il y a déjà un export en traitement",
    "exportData": "Exporter les données",
    "exportPhoto": "Exporter les photos",
    "feet": "pieds",
    "fieldObservation": "Observation terrain",
    "file": "Fichier",
    "forgotPwd": "Oublié",
    "forgotYourPassword": "Mot de passe oublié?",
    "fullName": "Nom complet",
    "fusion": "Fusion",
    "fusionAttrHelp": "Attributs en edition (limité)",
    "fusionShort": "Ⓕ",
    "ground": "Terrain",
    "group": "Groupe",
    "groupManager": "Gestion des groupes",
    "groups": "Groupes",
    "home": "Accueil",
    "imageCount": "{count, plural, =0 {Aucune photo} =1 {une photo trouvée} other {# photos trouvées}}",
    "infoAProject": "Information du projet",
    "invalid token": "Ce lien n’est plus valid",
    "invitationIsNoLongerValid": "Cette invitation n'est plus valide. \nCommuniquez avec l'administrateur du système pour obtenir une invitation valide.",
    "invitationNotForYou": "Il n'y a pas d'invitation pour {email}. Veuillez vous assurer d'étre connecté avec le bon usager.\nOu communiquez avec l'administrateur du système pour obtenir une invitation valide.",
    "invite": "Inviter",
    "inviteContributorsToJoinTheProject": "Invitez des contributeurs à joindre le projet.",
    "kml": "KML",
    "label": "Étiquette",
    "label_anchorAttrHelp": "Alignment du texte dans la boîte de texte",
    "label_angleAttrHelp": "Attribut ou nombre",
    "label_box_heightAttrHelp": "Hauteur de la boîte contentant le texte Défault 60",
    "label_box_widthAttrHelp": "Largeur de la boîte contentant le texte Défault 60",
    "label_correctionAttrHelp": "Correction class CSS",
    "label_heightAttrHelp": "Taille police",
    "label_offsetAttrHelp": "Déplacement de la boîte du point d'insertion",
    "label_outline_colorAttrHelp": "Coutour du texte",
    "label_position_strictAttrHelp": "Afficher même si le segment est plus court que la boîte de texte",
    "label_raw_angleAttrHelp": "Ne pas changer la rotation pour lecture",
    "label_theme_boundAttrHelp": "Afficher seulement si le thème spécifié s'affiche",
    "label_weightAttrHelp": "Gras (0-1000)",
    "layer": "Couche",
    "layers": "Couches",
    "leaveTheProject": "Quitter le projet",
    "leaveTheProjectInformation": "Si vous quitter ce projet, vous ne pourrez plus y accéder. Vous devrez recevoir une invitation pour rejoindre le projet à nouveau.",
    "light": "Clarté",
    "linestringSectionMsg": "Section géometrie Linestring",
    "locale_en": "English",
    "locale_fr": "Français",
    "locateMe": "Afficher mon emplacement",
    "locatePopup": "<br>Vous êtes a moins de {distance} {unit} de ce point<br><br>",
    "logIn": "Se connecter",
    "logInToZonedge": "Connectez-vous à Zonedge",
    "login": "Se connecter",
    "mainStyle": "Style principal",
    "map_editor": "Éditeur de carte",
    "map_zonedge": "Carte Zonedge",
    "marker_angleAttrHelp": "Attribut ou nombre",
    "marker_heightAttrHelp": "Défaut à 30",
    "marker_horizontal_alignAttrHelp": "Déplacement horizontal du point d'insertion",
    "marker_opacityAttrHelp": "(0.0-1.0)",
    "marker_vertical_alignAttrHelp": "Déplacement vertical du point d'insertion",
    "marker_widthAttrHelp": "Défaut à 30",
    "maxResultReach": "Trop de résultats",
    "max_zoomAttrHelp": "Affiche si le zoom de la carte est PLUS GRAND ou ÉGAL (0 est illimité)",
    "measure_end": "Marquage fin",
    "measure_length": "Longeur mesurée",
    "measure_start": "Marquage début",
    "members": "Membres",
    "menu": "Menu",
    "meters": "mètres",
    "min_zoomAttrHelp": "Affiche si le zoom de la carte est PLUS PETIT ou ÉGAL (0 est illimité)",
    "missing currentPassword": "Mot de passe actuel manquant",
    "missing name": "Nom manquant",
    "missing newPassword": "Nouveau mot de passe manquant",
    "missingEmail": "Adresse courriel manquant",
    "missingPassword": "Mot de passe manquant",
    "missingUserName": "Nom d'utilisateur manquant",
    "modelNameAlreadyUsed": "Ce modèle existe déjà",
    "municipality": "Municipalité",
    "myProjects": "Mes projets",
    "name": "Nom",
    "newLayerName": "Nouvelle couche",
    "newModelName": "Nom du nouveau modèle",
    "newPassword": "Nouveau mot de passe",
    "newSearch": "Nouvelle recherche",
    "newSymbol": "New Symbol",
    "newUser": "Nouveau compte",
    "noFibreToTrace": "Pas de fibre trouvée",
    "noInformation": "Aucune information",
    "noPermit": "No",
    "noProject": "No. Projet",
    "noResultsForTheseSearchCriteria": "Aucun résultats pour ces critères de recherche.",
    "not_found": "Non trouvé",
    "note": "Note",
    "noteRole": "Note",
    "noteRoleShort": "📋",
    "notes": "Notes",
    "ok": "OK",
    "onResult": "{searchOffset} sur {searchCount, plural, =1 {1 résultat} other {# résultats}}",
    "opacityAttrHelp": "(0.0-1.0)",
    "openPermit": "Réponse prévue",
    "options": "Options",
    "organization": "Organisation",
    "outlineStyle": "Style bordure",
    "owner": "Propriétaire",
    "password": "Mot de passe",
    "passwordChangedSuccessfully": "Mot de passe modifié avec succès.",
    "passwordDoNotMatch": "Le mot de passe ne correspond pas",
    "passwordMismatch": "Le nouveau mot de passe doit être le même dans le champ confirmer",
    "pendingInvitations": "Invitations en attente",
    "permissions": "Permissions",
    "permit": "Permis",
    "permitShort": "🫱",
    "plan": "Plan",
    "pleaseCreateANewPassword": "Veuillez créer un nouveau mot de passe contenant au moins 8 caractères.",
    "profile": "Profil",
    "project": "Projet",
    "projectID": "ID du projet",
    "projectName": "Nom du projet",
    "projects": "Projets",
    "read": "Lecture",
    "readOnly": "En lecture seule",
    "readShort": "📖",
    "receiverPermit": "Receveur",
    "removeCircuitLayerConfirm": "Voulez-vous supprimer la couche du circuit?",
    "resetPassword": "Réinitialiser le mot de passe",
    "resetPwd": "Réinitialisé",
    "resettingPasswordInstruction": "Saisissez votre adresse courriel et nous vous enverrons les instructions pour réinitialiser votre mot de passe.",
    "resettingYourPassword": "Réinitialisation de votre mot de passe",
    "restrictions": "Restrictions:",
    "rights": "Droits",
    "runUpdateSQL": "Exécuter SQL UPDDATE",
    "satellite": "Satellite",
    "satellitePlan": "Satellite-Plan",
    "save": "Sauvegarder",
    "savedSuccessfully": "Sauvegardé avec succès.",
    "saving": "Sauvegarde...",
    "search": "Recherche",
    "searchAddressKnowAvailableServices": "Recherchez une adresse pour connaitre les services disponibles.",
    "searchCircuit": "Rechercher un circuit",
    "searchResults": "Résultats de la recherche",
    "searchVerb": "Rechercher",
    "selectAFibreTracing": "Sélectionner une fibre à tracer",
    "selectToEditFusion": "Sélectionner pour modifier la fusion",
    "selectToEditPermit": "Sélectionner pour modifier le permis",
    "sendEmail": "Envoyer courriel",
    "sendEmailExplain": "Vous allez revevoir un courriel avec un lien de téléchargement quand les images seront prêtes.",
    "sendInstructions": "Envoyer les instructions",
    "services": "Services",
    "servicesAvailableAtTheAddress": "Services disponibles à l'adresse",
    "showMoreResults": "Afficher plus de résultats",
    "shp": "ShapeFile",
    "signOut": "Se déconnecter",
    "signup": "Créer un compte",
    "sorryAnErrorOccurred": "Désolé, une erreur s'est produite.",
    "status": "Statut",
    "statusTerrain": "Statut terrain",
    "street": "Rue",
    "super": "Super",
    "superShort": "ⓢ",
    "theChangesHaveBeenSavedSuccessfully": "Les changements ont été sauvegardés avec succès.",
    "theme": "Thème",
    "title": "Titre",
    "token expired": "Ce lien est expiré",
    "token not found": "Ce lien n’est plus valide",
    "trace": "Trace",
    "traceVerb": "Tracer",
    "tracingAttrHelp": "Quel element du détail et son attribut pour fibre id.",
    "twoFactorRequired": "Authentification à 2 facteurs",
    "type": "Type",
    "typeNote": "Type de note",
    "uniqueProjectIdentifier": "Identifiant unique du projet",
    "update": "Mettre à jour",
    "upstream": "Amont",
    "useDataLoader": "Charger des données",
    "user": "Usager",
    "user not_found": "Utilisateur non trouvé",
    "userManager": "Gestion des utilisateurs",
    "username": "Nom d'utilisateur",
    "users": "Utilisateurs",
    "version": "Version {value}",
    "viewMyProfile": "Afficher mon profil",
    "visibleZone": "Zone visible seulement",
    "weightAttrHelp": "Épaisseur du trait",
    "welcomeToZonedge": "Bienvenue dans Zonedge",
    "write": "Écriture",
    "writeShort": "✏️",
    "youHaveSuccessfullyJoinedTheProject": "Vous avez rejoint le projet avec succès. \nVous pouvez maintenant continuer.",
    "yourPasswordHasBeenSuccessfullyChanged": "Votre mot de passe a été modifié avec succès."
  }]];
});